import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import HorseRacing from "../components/HorseRacing";

const HorseRacingPage = ({ data }) => (
  <Layout>
    <HorseRacing data={data} />
  </Layout>
);

export default HorseRacingPage;

export const query = graphql`
  query {
    ownedhorse: file(relativePath: { eq: "owned-horse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
