import React from "react";
import Gallery from "react-photo-gallery";
import ReactPlayer from "react-player";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { Row, Col } from "react-styled-flexboxgrid";
import { Box } from "reflexbox";
import Slider from "react-styled-carousel";

import { styleBackgroundImage } from "../../components/Section/section.style";
import { Primary, Alternate } from "../../components/Section";
import { HrefButtonGhostInverse } from "../../components/Button";

import MJLeHandLogo from "../../images/mj-lehand-logo.jpg";
import SliderHorseFreedom from "../../images/slider-horse-freedom.jpg";
import SliderHorseRacingRed from "../../images/slider-horse-racing-red.jpg";
import SliderGroupHorses from "../../images/slider-group-horses.jpg";
import OwnedHorse01 from "../../images/owned-horse.jpg";
import OwnedHorse02 from "../../images/owned-horse-02.jpg";
import OwnedHorse03 from "../../images/owned-horse-03.jpg";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-horse-race.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
  background-position-y: 15%;
`;
const settings = {
  cardsToShow: 1,
  showArrows: false,
  padding: 0,
  margin: 0,
};
const SliderImage = styled.img`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const horseGallery = [
  {
    src: OwnedHorse01,
    width: 4,
    height: 3,
  },
  {
    src: OwnedHorse02,
    width: 3,
    height: 4,
  },
  {
    src: OwnedHorse03,
    width: 3,
    height: 4,
  },
];

const HorseRacing = ({ data }) => (
  <section>
    <StyledBackgroundSection centered>
      <Row>
        <Col xs={12}>
          <h1>Equine Spirit and Le Hand Racing</h1>
          <p>Thoroughbred Race Horsing Ownership at its best!</p>
        </Col>
      </Row>
    </StyledBackgroundSection>
    <Primary centered>
      <Row>
        <Col xs={12}>
          <h2>The Spirit of the Horse</h2>
          <blockquote>
            “When you are on a great horse, you have the best seat you will ever
            have.”
          </blockquote>
          <h3>- Sir Winston Churchill</h3>
          <Box>
            <Gallery photos={horseGallery} />
          </Box>
        </Col>
      </Row>
      <Box mb={16} />
      <Row>
        <Col xs={12} md={4}>
          <Box sx={{ display: `flex`, justifyContent: `center` }}>
            <ReactPlayer url="https://youtu.be/KH0yLmuP594" />
          </Box>
        </Col>
        <Col xs={12} md={4}>
          <Box sx={{ display: `flex`, justifyContent: `center` }}>
            <ReactPlayer url="https://youtu.be/bikNHBqdFzg" />
          </Box>
        </Col>
        <Col xs={12} md={4}>
          <Box sx={{ display: `flex`, justifyContent: `center` }}>
            <ReactPlayer url="https://youtu.be/hoEF49yGnxI" />
          </Box>
        </Col>
      </Row>
    </Primary>
    <section>
      <Slider {...settings}>
        <SliderImage src={SliderHorseRacingRed} alt="Horse Racing" />
        <SliderImage src={SliderHorseFreedom} alt="Silhouette horse running" />
        <SliderImage src={SliderGroupHorses} alt="Horse group racing fast" />
      </Slider>
    </section>

    <Alternate>
      <Row>
        <Col xs={12} md={6}>
          <h2>Le Hand Racing Partnerships</h2>
          <p>
            Did you know that the horses' height is classically measured in
            “hands”? We at MJ MD Global strive to manage the spirit of the horse
            to new heights and new history! Our bloodstock agent partner is a
            third-generation horseman and equine entrepreneur. See our articles
            on the history of horse racing.
          </p>
          <p>
            “We let the spirit of the horse guides us.” Dr. Michael J, Le Hand
            Syndicate Manager.
          </p>
          <HrefButtonGhostInverse href="/reach-us">
            Learn more and become a partner
          </HrefButtonGhostInverse>
        </Col>
        <Col xs={12} md={6}>
          <img src={MJLeHandLogo} alt="Le Hand Racing Logo" />
        </Col>
      </Row>
    </Alternate>
  </section>
);

export default HorseRacing;
